import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import { TitleBlockData } from '~src/components/elements';
import {
  Hero,
  HeroData,
  RoomsData,
  RoomsSection,
} from '~src/components/sections';
import type { SeoData } from '~src/layouts/components/seo';
import Layout from '~src/layouts/page-layout';
import type { StrapiRoomsPage } from '~src/types/generated/graphql-gatsby';

type Data = {
  strapiRoomsPage: StrapiRoomsPage;
};

const RoomsPage: FC<PageProps<Data>> = ({
  data: { strapiRoomsPage },
  location,
}) => {
  const seoData = strapiRoomsPage.seo as SeoData;
  const heroData = strapiRoomsPage.hero as HeroData;

  const titleBlockData = strapiRoomsPage.titleBlock as TitleBlockData;
  const roomsData = strapiRoomsPage.rooms as RoomsData;

  return (
    <Layout pathname={location.pathname} seoData={seoData}>
      <Hero data={heroData} />
      <RoomsSection data={roomsData} titleBlockData={titleBlockData} />
    </Layout>
  );
};

export default RoomsPage;

export const roomsPageQuery = graphql`
  query RoomsPage($locale: String!) {
    strapiRoomsPage(locale: { eq: $locale }) {
      seo {
        title
        description
        featuredImage {
          alt
          image {
            localFile {
              ...FeaturedImg
            }
          }
        }
      }
      hero {
        title
        coverImage {
          alt
          desktop {
            localFile {
              ...DesktopCoverImg
            }
          }
          phone {
            localFile {
              ...PhoneCoverImg
            }
          }
        }
      }
      titleBlock {
        title
        text
      }
      rooms {
        id
        pageID
        slug
        hero {
          title
          subtitle
          coverImage {
            alt
            desktop {
              localFile {
                ...RoomPreviewImg
              }
            }
          }
        }
      }
    }
  }
`;
